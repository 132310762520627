<template>
    <div class="input-group">
      <span class="input-group-text input-group-text--addon-left">
        <b-icon icon="search"></b-icon>
      </span>
      <input
        :value="modelValue"
        @input="updateValue"
        type="text"
        class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
        placeholder="搜索"
      />
    </div>
  </template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateValue (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
